<template>
    <!-- BEGIN: Top Bar -->
    <div class="top-bar">
        <UsersTopBar
            v-if="$route.name === 'funnel' || $route.name === 'dealEdit'"
            :pipeline_id="$route.params.id"
            class="flex-[1] mr-6"
        />
        <div class="ml-auto flex items-center flex-[0 1 72px]">
            <div class="notify mr-3 flex">
                <notifications type="lead" v-if="subscriptions.includes('lead')" />
                <notifications />
            </div>
            <div class="intro-x dropdown w-8 h-8 bg-white rounded-lg">
                <div
                    class="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center"
                    role="button"
                    aria-expanded="false"
                    @click="toggleSearchDropdown"
                >
                    <MoreVerticalIcon />
                </div>

                <div v-if="searchDropdown" class="top-bar-dropdown-menu w-56">
                    <div
                        v-click-outside="close"
                        class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
                    >
                        <div class="p-4 border-b border-theme-27 dark:border-dark-3">
                            <div class="font-medium"></div>
                            <!--            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600"></div>-->
                        </div>
                        <div
                            class="p-2 border-t border-theme-27 dark:border-dark-3"
                            v-if="this.$route.name == 'funnel'"
                        >
                            <a
                                href="#"
                                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                                @click.prevent="editFields"
                            >
                                <ListIcon class="w-4 h-4 mr-2" />
                                Настройка полей
                            </a>
                        </div>
                        <div
                            class="w-100 flex items-center p-2 border-t flex border-theme-27 dark:border-dark-3"
                            v-if="this.$route.name == 'funnel' && hasAdmin"
                        >
                            <input
                                name="file"
                                type="file"
                                id="input__file"
                                class="input input__file"
                                ref="file"
                                @change="editImage"
                            />
                            <label
                                for="input__file"
                                class="w-100 p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer w-full"
                            >
                                <UploadIcon class="w-4 h-4 mr-2" />
                                <span class="">Выберите изображение</span>
                            </label>
                        </div>
                        <div class="w-100 flex items-center p-2 border-t flex border-theme-27 dark:border-dark-3">
                            <a
                                href="#"
                                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md w-full"
                                @click.prevent="onLinkClick(`/users/${user.id}/edit`)"
                            >
                                <UserIcon class="w-4 h-4 mr-2" />
                                Профиль
                            </a>
                        </div>
                        <div class="w-100 flex items-center p-2 border-t flex border-theme-27 dark:border-dark-3">
                            <a
                                href="#"
                                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md w-full"
                                @click.prevent="logout"
                            >
                                <ToggleRightIcon class="w-4 h-4 mr-2" />
                                Выйти
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Account Menu -->
    </div>
    <!-- END: Top Bar -->
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import notifications from '@/views/users/notifications/Main';
import axios from '@/utils/axios';
import UsersTopBar from '@/components/top-bar/UsersTopBar.vue';

export default defineComponent({
    components: {
        UsersTopBar,
        notifications,
    },
    setup() {
        const searchDropdown = ref(false),
            toggleSearchDropdown = () => (searchDropdown.value = !searchDropdown.value),
            close = () => (searchDropdown.value = false),
            store = useStore(),
            router = useRouter(),
            user = computed(() => store.getters['user/getUser']),
            userLogout = () => store.dispatch('user/logOut'),
            onLinkClick = (url) => {
                toggleSearchDropdown();
                router.push(url);
            },
            onUserLogoutClick = () => {
                toggleSearchDropdown();
                userLogout();
            },
            editFields = () => {
                store.commit('user/setField');
                toggleSearchDropdown();
            },
            hasAdmin = computed(() => {
                return store.getters['user/hasAdmin'];
            }),
            subscriptions = computed(() => {
                return store.getters['user/getSubscriptions'];
            }),
            logout = () => {
                store.dispatch('user/logOut');
                router.push({
                    name: 'login',
                });
            };

        let users = [];
        axios.get('/users').then((data) => {
            users = data.data.data;
        });

        return {
            searchDropdown,
            editFields,
            toggleSearchDropdown,
            user,
            close,
            logout,
            onLinkClick,
            users,
            hasAdmin,
            subscriptions,
            onUserLogoutClick,
        };
    },
    methods: {
        editImage() {
            const file = this.$refs.file.files[0];
            let formData = new FormData();
            formData.append('image', file);
            formData.append('id', this.$route.params.id);
            this.$store.dispatch('user/downloadImage', formData);
            this.toggleSearchDropdown();
        },
    },
});
</script>

<style lang="scss" scoped>
.top-bar-dropdown-menu {
    position: absolute;
    right: 0;
    top: 40px;
}

.input__wrapper {
    width: 100%;
    position: relative;
    margin: 15px 0;
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file-icon-wrapper {
    height: 60px;
    width: 60px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #fff;
}

.input__file-button-text {
    line-height: 1;
    margin-top: 1px;
}

.crenditails {
    opacity: 0 !important;
}

.input__file-button {
    width: 100%;
    max-width: 290px;
    height: 60px;
    background: #1bbc9b;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
}
</style>