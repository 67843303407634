import dayjs from 'dayjs';

function message(notification) {
    const getType = function(type) {
        if (type === 'default') {
            return 'Создано в crm';
        }

        if (type === 'site') {
            return 'Заявка с сайта';
        }

        if (type === 'site_callback') {
            return 'Заказ звонка';
        }

        if(type === 'from_internal_page'){
            return 'Внутренняя форма';
        }
    };
    return `
        <p>
            Дата и время звонка: <b>${
                notification.payload.date ? dayjs(notification.payload.date).format('DD.MM.YYYY HH:mm') : 'Не указано'
            }</b>
        <br>
        </p>
        <p>
            Имя клиента: <b>${notification.payload.name ?? 'Не указано'}</b>
        </p>
        <p>
            Телефон: <b>${notification.payload.phone ?? 'Не указано'}</b>
        </p>
        <p>
            Email: <b>${notification.payload.email ?? 'Не указано'}</b>
        </p>
        <p>
            Комментарий: <b>${notification.payload.comment ?? 'Не указано'}</b>
        </p>
        <p>Источник: <b>${getType(notification.payload.type_lead)}</b></p>
    `;
}

export default function(notification) {
    const payload = notification.payload;
    return {
        id: notification.id,
        text: message(notification),
    };
}
