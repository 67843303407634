import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from '@/utils/axios';
import VueAxios from 'vue-axios';
import globalComponents from './global-components';
import Notifications from '@kyvg/vue3-notification';
import VueColor from '@ckpack/vue-color';
import Maska from 'maska';
import utils from './utils';
import Vuelidate from '@vuelidate/core';
import vClickOutside from 'click-outside-vue3';
import './libs';
import Echo from 'laravel-echo';
import {SetupCalendar} from 'v-calendar';
import 'v-calendar/dist/style.css';
import VueVirtualScroller from 'vue3-virtual-scroller';
import {plugin as VueTippy} from 'vue-tippy';
// SASS Theme
import './assets/sass/app.scss';

//
window.Pusher = require('pusher-js');

window.localStorage.setItem('cache', 'db');

window.Echo = new Echo({
    broadcaster: 'pusher',
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
    },
    key: '123',
    cluster: '124sxdhfbqawsf',
    wsHost: window.location.hostname,
    wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws'],
});

window.Echo.connector.pusher.connection.bind('connected', () => {
    axios.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId();
});
window.Echo.connector.pusher.connection.bind('disconnected', () => {
    console.log('disconnected');
});

await store.dispatch('user/verifyToken');
const app = createApp(App)
    .use(store)
    .use(VueColor)
    .use(router)
    .use(VueAxios, axios)
    .use(vClickOutside)
    .use(Vuelidate)
    .use(Maska)
    .use(Notifications)
    .use(VueVirtualScroller)
    .use(
        VueTippy,
        // optional
        {
            directive: 'VueTippy',
            component: 'VueTippy',
        }
    )
    .use(SetupCalendar, {});

window.can = function(permissions) {
    let _return = false;
    let userPermissions = store.getters['user/getUser'].userPermissions;
    permissions.split('|').some(function(item) {
        if (userPermissions.includes(item.trim())) {
            _return = true;
            return true;
        }
    });

    return true;
};

window.is_admin = function(user) {
    if (user.role_title.includes('admin')) {
        return store.getters['user/getUser'].is_admin;
    }
    return false;
};

app.config.performance = true;
globalComponents(app);
utils(app);

app.provide('axios', axios);
app.provide('VueAxios', VueAxios);

app.mount('#app');

window.Echo.private('App.Models.User.' + store.getters['user/getUser'].id)
    .listen('AddedUserPipelineEvent', async (event) => {
        store.commit('sideMenu/addAddedEventMenu', event.data);
    })
    .listen('UpdatedSubscriptionEvent', (event) => {
        store.commit('user/setSubscriptions', event.data);
    })
    .listen('DetachedUserPipelineEvent', async (event) => {
        store.commit('sideMenu/detachedMenu', event.data);
    });
