<template>
    <div
        v-if="date"
        class="relative flex items-center px-2 py-1 rounded font-bold w-fit"
        :class="{
            'bg-green-200 text-green-700': !isDateExpiring && !isDateOverdue,
            'bg-yellow-200 text-yellow-700': isDateExpiring,
            'bg-red-200 text-red-700': isDateOverdue,
        }"
    >
        <clock-icon class="w-4 h-4 mr-2" />
        <div>
            <template v-if="date.start_date == date.end_date">
                <span v-if="date.end_date">{{ end }}</span>
            </template>
            <template v-else>
                <template v-if="date.select_start_date">
                    <span v-if="date.start_date">{{ start }}</span>
                    <span v-if="date.end_date && date.start_date"> - </span>
                    <span v-if="date.end_date">{{ end }}</span>
                </template>
                <template v-else>
                    <span v-if="date.end_date">{{ end }}</span>
                    <span v-else>{{ start }}</span>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: "NotificationDate",
    props: {
        date: {
            required: true,
        },
    },
    mounted() {
        const today = dayjs().startOf('day');
        let startDate = dayjs(this.date.start_date);
        let endDate = dayjs(this.date.end_date);
        const diff = today.diff(endDate, 'day');
    },
    computed: {
        start() {
            return dayjs(this.date.start_date).format('D MMM');
        },
        end() {
            return dayjs(this.date.end_date).format('D MMM');
        },
        isDateExpiring() {
            if (!this.date.end_date) {
                return false;
            }

            const today = dayjs().startOf('day');
            const endDate = dayjs(this.date.end_date);
            const diff = today.diff(endDate, 'day');

            return (diff === -1 || diff === 0) && today < endDate;
        },
        isDateOverdue() {
            if (!this.date.end_date) {
                return false;
            }

            const today = dayjs().endOf('day');
            const endDate = dayjs(this.date.end_date);

            return today > endDate;
        },
    },
}
</script>

<style scoped lang="scss"></style>
