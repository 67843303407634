<template>
    <div v-click-outside="() => (this.showNotify = false)">
        <div class="bg-white p-1 rounded-full cursor-pointer relative">
            <div class="flex items-center" @click="showNotify = true">
                <BellIcon v-if="type !== 'lead'" class="w-7 h-7 mr-1 last:mr-0" />
                <MessageSquareIcon v-if="type === 'lead'" class="w-7 h-7 mr-1 last:mr-0" />
                <div v-if="count" class="w-6 rounded-full bg-red-500 text-white text-center font-bold text-12">
                    <small>{{ count }}</small>
                </div>
            </div>
            <div v-show="showNotify">
                <NotificationsModal
                    class="absolute top-[44px] right-[-40px] w-[432px] overflow-hidden"
                    :isShowOnlyNotReadNotifications="isShowOnlyNotReadNotifications"
                    @on-change-only-not-read="changeOnlyNotRead"
                    @close="showNotify = false"
                >
                    <template v-slot:content>
                        <div class="min-h-[400px] flex flex-col">
                            <template v-if="notifications && notifications.length">
                                <div class="flex justify-between mb-2">
                                    <button
                                        v-if="order_created_at === 'asc'"
                                        type="button"
                                        @click="order_created_at = 'desc'"
                                    >
                                        показать снчала новые
                                    </button>
                                    <button
                                        v-if="order_created_at === 'desc'"
                                        type="button"
                                        @click="order_created_at = 'asc'"
                                    >
                                        показать снчала старые
                                    </button>
                                    <button type="button" @click="readAllNotifications">
                                        Отметить все как прочитанное
                                    </button>
                                </div>
                                <div
                                    class="flex flex-col gap-3 bg-blue-100 px-4 py-2 -mx-3 max-h-[400px] custom-scroll overflow-auto"
                                    @scroll="onScroll"
                                >
                                    <div
                                        v-if="fetch_paginate"
                                        class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center disabled opacity-40"
                                    >
                                        <Preloader />
                                    </div>
                                    <div v-for="(arr, day) in mapNotifications">
                                        <div class="text-center mb-2 wrapper-day-notify">
                                            <span class="day-notify">{{ day }}</span>
                                        </div>
                                        <UserNotification
                                            v-for="notify in arr"
                                            :key="notify.id"
                                            class="mb-4 notify"
                                            :notify="notify"
                                            :isShowPipeline="notify.pipeline"
                                            @on-change-route="showNotify = false"
                                            @show-modal-user="handleShowModalUser"
                                            @clickMessage="handleClickMessage"
                                            @readNotify="readNotify"
                                            @onClickNotify="
                                                () => {
                                                    if (!notify.read) {
                                                        readNotify(notify.id);
                                                    }
                                                }
                                            "
                                        />
                                    </div>
                                </div>
                            </template>
                            <div v-else class="flex flex-col gap-8 items-center justify-center w-full flex-[1] mb-10">
                                <NoNotificationsImage />
                                <p class="text-xl mb-0">
                                    {{
                                        getIsShowOnlyNotReadNotifications
                                            ? 'Нет непрочитанных уведомлений'
                                            : 'Нет уведомлений'
                                    }}
                                </p>
                            </div>
                        </div>
                    </template>
                </NotificationsModal>

                <ModalJntUser
                    v-if="modalUser"
                    :user-id="userId"
                    v-on:close="modalUser = false"
                    v-on:update="updateUser"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import useFaviconBadgeNotify from 'favicon-badge-notify';
import { errorResponse } from '@/mixins/form/form-mixin';
import NotificationsModal from '@/components/modals/notifications/NotificationsModal';
import UserNotification from '@/components/notifications/UserNotification';
import NoNotificationsImage from '@/components/icons/NoNotificationsImage';
import ModalJntUser from '@/components/modals/modals-users/ModalJnt';
import factoryMessageByNotification from '@/views/users/notifications/Mappers/factory-message-by-notification';
import store from '@/store';
import dayjs from 'dayjs';
import debounce from 'debounce';
import Preloader from '@/components/preloader/Main';

export default {
    name: 'UserNotifications',
    components: {
        NotificationsModal,
        UserNotification,
        NoNotificationsImage,
        ModalJntUser,
        Preloader,
    },
    mixins: [errorResponse],
    props: {
        type: {
            default: 'deal',
        },
    },
    async mounted() {
        this.audio = new Audio('/notification.mp3');
        const { drawBadge, destroyBadge } = await useFaviconBadgeNotify({
            src: '/icon.png',
        });
        this.drawBadge = drawBadge;
        this.destroyBadge = destroyBadge;

        await this.fetchData(
            this.getIsShowOnlyNotReadNotifications ? 1 : 0,
            this.order_created_at,
            this.page,
            this.type
        );
        const { data } = await this.axios.get('/notification/count-deal-unread');
        store.commit('user/setUnreadDeal', data);
        this.chanel = window.Echo.private('App.Models.User.' + store.getters['user/getUser'].id)
            .listen('NewNotificationEvent', async (event) => {
                if (
                    (this.type === 'lead' && event.data.type === 'create_lead') ||
                    (this.type === 'deal' && event.data.type !== 'create_lead')
                ) {
                    this.count_notify += 1;
                    this.notifications.splice(0, 0, event.data);
                    store.commit('user/incrementUnreadDeal', event.data.payload.deal_id);
                    if (!this.is_played && this.getIsSoundNotify) {
                        this.is_played = true;
                        try {
                            if (event.sound) {
                                const audio = new Audio(event.sound);
                                await audio.play();
                            } else {
                                await this.audio.play();
                            }
                        } catch (e) {
                            console.warn('user dont use document');
                        }
                        setTimeout(() => (this.is_played = false), 300);
                    }
                }
            })
            .listen('ReadedNotificationEvent', (event) => {
                if (
                    (this.type === 'lead' && event.payload.type === 'create_lead') ||
                    (this.type === 'deal' && event.payload.type !== 'create_lead')
                ) {
                    const notify = this.notifications.find((el) => el.id === event.notify_id);

                    if (notify) {
                        if (dayjs(notify.updated_at) <= dayjs(event.updated_at)) {
                            if (event.read_at) {
                                this.count_notify -= 1;
                                store.commit('user/decrementUnreadDeal', event.payload.deal_id);
                            } else {
                                this.count_notify += 1;
                                store.commit('user/incrementUnreadDeal', event.payload.deal_id);
                            }
                            notify.read_at = event.read_at;
                            notify.updated_at = event.updated_at;
                            if (this.getIsShowOnlyNotReadNotifications) {
                                const index = this.notifications.findIndex((el) => el.id === notify.id);
                                this.notifications.splice(index, 1);
                            }
                        }
                    } else {
                        if (event.read_at) {
                            this.count_notify -= 1;
                        } else {
                            this.count_notify += 1;
                        }
                    }
                }
            })
            .listen('ReadedAllNotificationEvent', (event) => {
                if (event.type === this.type) {
                    this.notifications
                        .filter(
                            (notify) =>
                                dayjs(notify.updated_at) < dayjs(event.date) && Boolean(notify.read_at) === false
                        )
                        .forEach((notify) => {
                            notify.read_at = event.date;
                        });
                    const count_notify = this.count_notify - event.count_readed;

                    if(count_notify < 0){
                        this.count_notify = 0;
                    }else {
                        this.count_notify = count_notify;
                    }
                }
                if (event.type === 'deal') {
                    store.commit('user/setUnreadDeal', event.unreaded_deal);
                }
            })
            .listenForWhisper('ChangedReadNotify', async (event) => {
                if (this.type === event.type) {
                    this.isShowOnlyNotReadNotifications = Number(event.value);
                    localStorage.setItem(
                        'show_only_not_read_notifications',
                        Number(this.isShowOnlyNotReadNotifications)
                    );
                    await this.fetchData(
                        this.isShowOnlyNotReadNotifications,
                        this.order_created_at,
                        this.page,
                        this.type
                    );
                }
            });
    },
    data() {
        return {
            page: 1,
            total: 0,
            isShowOnlyNotReadNotifications: localStorage.getItem('show_only_not_read_notifications')
                ? JSON.parse(localStorage.getItem('show_only_not_read_notifications'))
                : 0,
            chanel: null,
            fetch_paginate: false,
            drawBadge: null,
            order_created_at: 'desc',
            destroyBadge: null,
            is_played: false,
            audio: null,
            userEdit: {},
            notifications: [],
            userId: 0,
            count_notify: 0,
            modalUser: false,
            showNotify: false,
        };
    },
    watch: {
        count_notify: function() {
            if (this.type !== 'lead') {
                const favicon = document.querySelector("link[rel~='icon']");
                this.drawBadge(this.count_notify).then((badge) => (favicon.href = badge));
                if (this.count_notify === 0) {
                    document.title = 'crm';
                    return;
                }

                document.title = `(${this.count_notify}) crm`;
            }
        },
        order_created_at: async function() {
            await this.fetchData(this.getIsShowOnlyNotReadNotifications ? 1 : 0, this.order_created_at, 1, this.type);
        },
    },
    computed: {
        ...mapGetters({
            getIsShowOnlyNotReadNotifications: 'user/getIsShowOnlyNotReadNotifications',
            getIsSoundNotify: 'user/getIsSoundNotify',
        }),
        getIsShowOnlyNotReadNotifications() {
            return this.isShowOnlyNotReadNotifications;
        },
        count() {
            const count = this.count_notify;

            return count >= 99 ? '99+' : count;
        },
        mapNotifications() {
            const map = {};

            if (this.notifications.length > 0) {
                this.notifications.forEach((notify) => {
                    const day = dayjs(notify.created_at).format('D MMMM');
                    const mapNotify = factoryMessageByNotification(notify);
                    mapNotify.read = Boolean(notify.read_at);
                    if (map[day]) {
                        map[day].push(mapNotify);
                    } else {
                        map[day] = [];
                        map[day].push(mapNotify);
                    }
                });
            }
            return map;
        },
    },
    methods: {
        onScroll: debounce(async function(event) {
            const calculateScroll = event.target.scrollTop + event.target.clientHeight;
            /// есть проблема почему-то толлько при первой прокрутке нужно добавить +1 , но потом итс окей
            if (
                (event.target.scrollHeight === calculateScroll || event.target.scrollHeight === calculateScroll + 1) &&
                !this.fetch_paginate
            ) {
                if (this.total > this.notifications.length) {
                    this.page += 1;
                    this.fetch_paginate = true;
                    await this.fetchData(
                        this.getIsShowOnlyNotReadNotifications ? 1 : 0,
                        this.order_created_at,
                        this.page,
                        this.type
                    );
                    this.fetch_paginate = false;
                }
            }
        }, 500),
        async fetchData(is_read = 0, order_created_at = 'desc', page = 1, type = null) {
            const { data } = await this.axios.get(
                `/notification?is_read=${is_read}&order_created_at=${order_created_at}&page=${page}&type=${type}`
            );
            if (page === 1) {
                this.notifications = data.data;
                this.count_notify = data.count;
                this.page = 1;
            } else {
                this.notifications.push(...data.data);
            }
            this.total = data.meta.total;
        },
        async handleClickMessage(data) {
            const notify = this.notifications.find((notify) => notify.id === data.id);
            if (notify.payload.type === 'create_lead' && notify.payload.lead_id) {
                await this.$router.push({ name: 'leadEdit', params: { id: notify.payload.lead_id } });
                if (!notify.read_at) {
                    await this.readNotify(notify.id);
                }
            }
            if (notify.payload.type === 'attach_user_pipeline') {
                await this.$router.push({ name: 'funnel', params: { id: notify.payload.pipeline_id } });
                if (!notify.read_at) {
                  await this.readNotify(notify.id);
                }
            }
            if (notify.payload.type === 'detach_user_pipeline') {
              if (!notify.read_at) {
                await this.readNotify(notify.id);
              }
            }
        },
        async changeOnlyNotRead(value) {
            this.isShowOnlyNotReadNotifications = Number(value);
            this.chanel.whisper('ChangedReadNotify', {
                value: value,
                type: this.type,
            });
            localStorage.setItem('show_only_not_read_notifications', Number(value));
            await this.fetchData(value, this.order_created_at, 1, this.type);
        },
        async readNotify(id) {
            const index = this.notifications.findIndex((item) => item.id === id);

            if (index !== -1) {
                let item = this.notifications[index];
                await this.axios.post(`notification/read/${id}`);
                if (this.count_notify !== 0 && !item.read_at) {
                    this.count_notify -= 1;
                    if (item.payload.deal_id) {
                        store.commit('user/decrementUnreadDeal', item.payload.deal_id);
                    }
                } else {
                    this.count_notify += 1;
                    if (item.payload.deal_id) {
                        store.commit('user/incrementUnreadDeal', item.payload.deal_id);
                    }
                }

                item.read_at = !this.notifications[index].read_at;
                if (this.getIsShowOnlyNotReadNotifications) {
                    this.notifications.splice(index, 1);
                } else {
                    this.notifications.splice(index, 1, item);
                }
            }
        },
        async readAllNotifications() {
            try {
                const response = await this.axios.post('/notification/read-all', {
                    type: this.type,
                });

                if (this.getIsShowOnlyNotReadNotifications) {
                    this.notifications = [];
                } else {
                    this.notifications.forEach((item) => {
                        item.read_at = true;
                    });
                }
                store.commit('user/readAllUnreadDeal');
                this.count_notify = 0;
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        handleShowModalUser(user) {
            this.userEdit = user;
            this.userId = user.id;
            this.modalUser = true;
        },
        updateUser(data) {
            this.userEdit[this.typeUser] = data;
        },
    },
};
</script>

<style scoped lang="scss">
.custom-scroll {
    scrollbar-width: auto;
    scrollbar-color: rgba(96, 111, 196, 0.97);
}

.custom-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(96, 111, 196, 0.97);
    border-radius: 9em;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: rgba(147, 157, 211, 0.97);
}

.day-notify {
    color: white;
    font-weight: 800;
    display: inline-block;
    padding: 4px;
    border-radius: 8px;
    background-color: rgba(30, 44, 58, 0.8);
}

.wrapper-day-notify {
    position: sticky;
}

.notify:last-child {
    margin-bottom: 0;
}
</style>
