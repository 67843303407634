import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import SideMenu from '@/layouts/side-menu/Main.vue';
import Login from '@/views/login/Main.vue';
import ErrorPage from '@/views/error-page/Main.vue';
import { notify } from '@kyvg/vue3-notification';
import roszdravnadzor from '@/router/roszdravnadzor';
import { TYPE_CERT, TYPE_CERT_PRODUCTION, TYPE_NEWCERT, TYPE_ROS } from '@/constants/qr-codes';
import { RUSTEST_ROLE } from '@/constants/roles';
import reestrsRoute from '@/router/reestrs.route';

const routes = [
    {
        path: '/',
        component: SideMenu,
        meta: {
            auth: ['Super-Admin', 'Admin', 'Manager'],
        },
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
                component: () => import('@/views/dashboard/Main.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/users/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },

            {
                path: '/users/create',
                name: 'userCreate',
                component: () => import('@/views/users/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/users/:id/edit',
                name: 'userEdit',
                component: () => import(/* webpac
        kChunkName: "user-edit" */ '@/views/users/Edit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/permissions',
                name: 'permissions',
                component: () => import('@/views/permission/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/qrcodes',
                name: 'qrcodes',
                redirect: { name: 'qr-codes-' + TYPE_CERT },
            },
            {
                path: '/qrcodes/:type/create',
                name: 'qr-codes-create',
                component: () => import('@/views/qrcodes/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/qrcodes/:type/edit/:id',
                name: 'qr-codes-edit',
                component: () => import('@/views/qrcodes/Update.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/qrcodes/' + TYPE_CERT,
                name: 'qr-codes-' + TYPE_CERT,
                redirect: { name: 'qr-codes-' + TYPE_CERT + '-category', params: { type: TYPE_CERT_PRODUCTION } },
                // component: () =>
                //     import(/* webpackChunkName: "qrcodes_cert
                // " */ '@/views/qrcodes/CertView.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
                children: [
                    {
                        path: ':type',
                        name: 'qr-codes-' + TYPE_CERT + '-category',
                        component: () => import('@/views/qrcodes/CertView.vue'),
                    },
                ],
            },

            {
                path: '/qrcodes/' + TYPE_ROS,
                name: 'qr-codes-' + TYPE_ROS,
                component: () =>
                    import(/* webpackChunkName: "qrcodes_ros
                " */ '@/views/qrcodes/RostestView.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
                // children: [(path: ':category')],
            },

            {
                path: '/qrcodes/' + TYPE_NEWCERT,
                name: 'qr-codes-' + TYPE_NEWCERT,
                component: () => import('@/views/qrcodes/NewCertView.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
                // children: [(path: ':category')],
            },

            {
                path: '/pipeline/settings',
                name: 'funnelSettings',
                component: () => import(/* webpackChunkName: "users" */ '@/views/pipeline/MainSettings.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/inbox',
                name: 'inbox',
                component: () => import('@/views/yandex-email/Main.vue'),
                children: [
                    {
                        path: ':uuid/:folder',
                        name: 'message-inbox',
                        component: () => import('@/views/yandex-email/Items-mail'),
                    },
                    {
                        path: ':uuid/reply-message/:folder/:uid/',
                        component: () => import('@/views/yandex-email/ReplyMessage'),
                        name: 'reply-message',
                    },
                    {
                        path: 'show/:uuid/:folder/:id',
                        name: 'inbox-show',
                        component: () => import('@/views/yandex-email/Show'),
                    },
                    {
                        path: ':uuid/create',
                        name: 'createInbox',
                        component: () => import(/* webpackChunkName: "users" */ '@/views/yandex-email/Create'),
                        meta: {
                            auth: ['Super-Admin', 'Admin'],
                        },
                    },
                ],
            },
            {
                path: '/inbox/auth',
                component: () => import('@/views/yandex-email/Auth.vue'),
                name: 'auth-inbox',
            },
            {
                path: '/pipeline/:id/edit',
                name: 'FunnelEdit',
                component: () => import('@/views/pipeline/PipelineEdit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/pipeline/create',
                name: 'FunnelCreate',
                component: () => import('@/views/pipeline/PipelineCreate.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/pipeline/:id/show',
                name: 'funnel',
                component: () => import('@/views/pipeline/Main.vue'),
                children: [
                    {
                        path: 'deal/:deal_id',
                        name: 'dealEdit',
                        component: () => import('@/views/deals/Edit.vue'),
                        meta: {
                            auth: ['Super-Admin', 'Admin'],
                        },
                    },
                ],
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/leads',
                name: 'leads',
                component: () => import('@/views/lead/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/leads/:id/edit',
                name: 'leadEdit',
                component: () => import('@/views/lead/Edit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/leads/create',
                name: 'applicationCreate',
                component: () => import('@/views/lead/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/role/create',
                name: 'roleCreate',
                component: () => import('@/views/permission/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/role/:id/edit',
                name: 'roleEdit',
                component: () => import('@/views/permission/Edit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin'],
                },
            },
            {
                path: '/clients',
                name: 'clients',
                component: () => import('@/views/clients/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/documents',
                name: 'document',
                component: () => import('@/views/documents/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/documents/:id/edit',
                name: 'documentEdit',
                component: () => import('@/views/documents/Edit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/documents/create',
                name: 'documentCreate',
                component: () => import('@/views/documents/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },

            ...roszdravnadzor,
            ...reestrsRoute,

            {
                path: '/company',
                name: 'company',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/clients/company/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/company/create',
                name: 'companyCreate',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/clients/company/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/company/:id/edit',
                name: 'companyEdit',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/clients/company/Edit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/clients/contacts/Main.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/contact/:id/edit',
                name: 'contactEdit',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/clients/contacts/Edit.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
            {
                path: '/contact/create',
                name: 'contactCreate',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/clients/contacts/Create.vue'),
                meta: {
                    auth: ['Super-Admin', 'Admin', 'Manager'],
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/error-page',
        name: 'error-page',
        component: ErrorPage,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundPage',
        component: ErrorPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    // общедоступные роуты
    const allowed_routes = ['/login', '/:pathMatch(.*)*', '/error-page'];
    if (allowed_routes.includes(to.path)) return next();

    // получение пользователя
    const token = localStorage.getItem('access_token');
    const currentUser = store.getters['user/isAuthenticated'];

    if (!currentUser) {
        if (!token) {
            return next({ path: '/login' });
        }
        // await store.dispatch('user/getUser');
        // try {
        // window.Echo.private('App.Models.User.' + store.getters['user/getUser'].id).notification((notification) => {
        //     store.commit('user/setNewNotify', notification);
        //     notify('Новое уведомление');
        // })

        // TODO костылька для рустеста сори не придумал ничего лучше времени нет
        const userAccessRules = store.getters['user/getUser'].role_title;
        if (to.name === 'home' && userAccessRules.length === 1 && userAccessRules.includes(RUSTEST_ROLE)) {
            return next({ name: 'qr-codes-' + TYPE_NEWCERT });
        }

        if (
            !to.path.match('qrcodes/newcert|qrcodes/ros') &&
            userAccessRules.length === 1 &&
            userAccessRules.includes(RUSTEST_ROLE)
        ) {
            return next({ name: 'NotFoundPage' });
        }

        // } catch (ex) {
        //   console.log('cant verify user by token: ' + ex);
        // }
    }

    // получение ролей
    // const userAccessRules = store.getters['user/getUserRole'];
    // const { auth: pageRoles } = to.meta;
    // if (pageRoles.length > 0) {
    //   if (pageRoles.includes(userAccessRules)) return next();
    //   console.log('ACCESS RULES REJECTED');
    //   return next({ name: 'error-page' });
    // }
    next();
});

export default router;
